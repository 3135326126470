import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import withApollo from 'next-with-apollo';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { getCookie } from '~utils/cookie-operation';
import getConfig from 'next/config';
import Store from '~store';
import { Router, i18n } from '~i18n';
import {
  USER_UNAUTHORIZED,
  UNAUTHORIZED,
  TOO_MANY_BOOKINGS,
  INVALID_ACADEMIC_YEAR,
  INVALID_TENANCY_OPTION_ID,
  INVALID_ROOM_TYPE_ID,
  INSTALLMENT_PLAN_CHANGED,
  BOOKING_EXPIRED,
  INVALID_BOOKING_ID,
  INVALID_NODE_ID,
} from '~constants/graphql-error';
import { displayWarnToast, modalDisappear } from '~store/modal/actions';
import { Authorization } from '~constants/cookie-properties';
import { deleteCookie } from '~utils/cookie-operation';

const { publicRuntimeConfig } = getConfig();
export default withApollo(
  ({ ctx, headers, initialState }) => {
    const dispatch = Store.dispatch;
    const t = i18n.t.bind(i18n);

    return new ApolloClient({
      credentials: 'include',
      uri: publicRuntimeConfig.GRAPHQL_ENDPOINT,
      fetchOptions: {
        credentials: 'include',
      },
      request: operation => {
        if (typeof window !== 'undefined' && window) {
          const token = getCookie(Authorization);
          operation.setContext({
            headers: {
              [Authorization]: token || '',
            },
          });
        }
      },
      onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            if (message.includes('INVALID_STATUS_FOR')) {
              dispatch(
                displayWarnToast({
                  isOpen: true,
                  title: t('modal.booking_details.changed'),
                  content: t('modal.bed_invalid_status.label'),
                  btnText: t('modal.booking_details.btn'),
                  onConfirm: () => {
                    Router.reload();
                    dispatch(modalDisappear());
                  },
                })
              );
            }
            if (message === TOO_MANY_BOOKINGS) {
              dispatch(
                displayWarnToast({
                  isOpen: true,
                  title: t('modal.to_many_bookings.title'),
                  content: t('modal.to_many_bookings.label'),
                  btnText: t('modal.to_many_bookings.btn'),
                  onConfirm: () => {
                    dispatch(modalDisappear());
                    Router.replace('/my-bookings');
                  },
                })
              );
            } else if (
              message === INVALID_ACADEMIC_YEAR ||
              message === INVALID_TENANCY_OPTION_ID ||
              message === INVALID_ROOM_TYPE_ID
            ) {
              dispatch(
                displayWarnToast({
                  isOpen: true,
                  title: t('modal.selection_unavailable.title'),
                  content: t('modal.selection_unavailable.label'),
                  btnText: t('modal.selection_unavailable.btn'),
                  onConfirm: () => {
                    dispatch(modalDisappear());
                    Router.replace('/residences');
                  },
                })
              );
            } else if (message === INSTALLMENT_PLAN_CHANGED) {
              dispatch(
                displayWarnToast({
                  isOpen: true,
                  title: t('modal.payment_updated.title'),
                  content: t('modal.payment_updated.label'),
                  btnText: t('modal.payment_updated.btn'),
                  onConfirm: () => {
                    Router.reload();
                    dispatch(modalDisappear());
                  },
                })
              );
            } else if (message === INVALID_BOOKING_ID || message === INVALID_NODE_ID) {
              dispatch(
                displayWarnToast({
                  isOpen: true,
                  title: t('modal.invalid_bookingId.title'),
                  content: t('modal.invalid_bookingId.label'),
                  btnText: t('modal.to_many_bookings.btn'),
                  onConfirm: () => {
                    dispatch(modalDisappear());
                    Router.replace('/my-bookings');
                  },
                })
              );
            } else if (message === BOOKING_EXPIRED) {
              dispatch(
                displayWarnToast({
                  isOpen: true,
                  title: t('modal.booking_expired.title'),
                  content: t('modal.booking_expired.label'),
                  btnText: t('modal.booking_expired.btn'),
                  onConfirm: () => {
                    dispatch(modalDisappear());
                    Router.replace('/my-bookings');
                  },
                })
              );
            }
            if (message === USER_UNAUTHORIZED || message === UNAUTHORIZED) {
              if (typeof window !== 'undefined' && window) {
                deleteCookie(Authorization);
                Router.push('/login');
              }
            }
          });
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
      },
      cache: new InMemoryCache().restore(initialState || {}),
    });
  },
  {
    render: ({ Page, props }) => {
      const { apollo } = props;
      return (
        <ApolloProvider client={apollo}>
          <Page {...props} />
        </ApolloProvider>
      );
    },
  }
);
